@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "tom-select/dist/css/tom-select.css";

form label {
  @apply block text-sm font-semibold leading-6 text-zinc-800;
}

form input[type="text"],
form input[type="date"],
form input[type="email"],
form input[type="password"],
form input[type="search"],
form input[type="number"] {
  @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm;
}

form textarea {
  @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm;
}

form input[type="checkbox"] {
  @apply rounded border-zinc-300 text-primary-500 focus:ring-primary-500;
}

form select {
  @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm;
}

form .cpq-field-error select {
  @apply border-red-500;
}

.is-invalid,
.is-invalid .ts-control {
  @apply !border-red-500;
}

.ts-wrapper .ts-control {
  @apply !mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm;
}

.ts-wrapper.focus .ts-control {
  @apply ring-primary-500 border-primary-500;
}

.button[disabled] {
  @apply !bg-gray-300 !cursor-not-allowed;
}

.button.primary {
  @apply inline-flex items-center rounded-md border border-transparent bg-primary-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 cursor-pointer;
}

.button.white {
  @apply inline-flex items-center rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 cursor-pointer;
}

.button.danger {
  @apply inline-flex items-center rounded-md border border-red-200 bg-white px-4 py-2 text-sm font-medium text-red-800 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 cursor-pointer;
}

a.link,
button.link {
  @apply text-sky-600 hover:text-sky-800 hover:underline cursor-pointer;

  &.active {
    @apply underline;
  }
}

table.jmi.table {
  @apply w-full shadow min-w-full divide-y divide-gray-300;
}

/* 1c355c */
table.jmi.table thead {
  @apply bg-gray-600;
}

table.jmi.table thead th {
  @apply px-3.5 py-2 text-left text-sm font-semibold text-gray-50 ;
}

table.jmi.table tbody {
  @apply divide-y divide-gray-200 bg-white;
}

table.jmi.table tfoot td{
  @apply bg-gray-50 text-base text-gray-900 ;
}


table.jmi.table tbody tr:nth-child(odd) {
  @apply bg-gray-200 hover:bg-gray-300;
}

table.jmi.table tbody tr:nth-child(even) {
  @apply bg-gray-100 hover:bg-gray-300;
}

table.jmi.table tbody td {
  @apply px-3.5 py-2 text-base text-gray-900;
}

table.jmi.celled.table tbody td {
  @apply border-l border-t border-gray-50;
}

table.jmi.table tfoot td {
  @apply px-3.5 py-4 text-sm text-gray-900;
}

.pagy-nav.pagination {
  @apply isolate inline-flex -space-x-px rounded-md shadow-sm;
}

.page.next a {
  @apply relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20;
}

.page.prev a {
  @apply relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20;
}

.page.next.disabled {
  @apply relative inline-flex items-center rounded-r-md border border-gray-300 bg-slate-100 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20;
}

.page.prev.disabled {
  @apply relative inline-flex items-center rounded-l-md border border-gray-300 bg-slate-100 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20;
}

.page a,
.page.gap {
  @apply bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20;
}

.page.active {
  @apply z-10 border-indigo-500 bg-indigo-50 text-indigo-600 relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20;
}

.order-steps {
  @apply border-b border-r border-l rounded border-gray-200;
}
.order-step {
  @apply py-2 px-6 text-center flex-grow relative bg-gray-100 text-gray-800 font-semibold;
}

.order-step.active {
  @apply bg-white text-black font-semibold shadow-lg;
}

.order-step.completed {
  @apply bg-primary-500 text-white;
}

.order-step.active:not(:last-child)::after {
  @apply bg-white;
}

.order-step.completed:not(:last-child)::after {
  @apply bg-primary-500;
  border-color: #dae1e7;
}

.order-step:not(:last-child)::after {
  @apply bg-gray-100;
  display: block;
  position: absolute;
  z-index: 2;
  content: "";
  top: 50%;
  right: -1.5px;
  border: medium #dae1e7;
  width: 1.14285714em;
  height: 1.14285714em;
  border-style: solid;
  border-width: 0 1px 1px 0;
  -webkit-transition: background-color 0.1s ease, opacity 0.1s ease,
    color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: background-color 0.1s ease, opacity 0.1s ease, color 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  transition: background-color 0.1s ease, opacity 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease;
  transition: background-color 0.1s ease, opacity 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-transform: translateY(-50%) translateX(50%) rotate(-45deg);
  transform: translateY(-50%) translateX(50%) rotate(-45deg);
}

.order-step:not(:last-child) {
  @apply border-r border-gray-200;
}

.ts-wrapper.single .ts-control,
.ts-wrapper.single .ts-control input {
  @apply text-base;
}

.ts-dropdown-content {
  @apply text-base;
}

.pm.card {
  @apply divide-y divide-gray-200 overflow-hidden border border-gray-200 rounded-lg bg-white shadow;
}

.pm.card .header {
  @apply px-4 py-5 sm:px-6 border-l-[6px] border-primary-500;
}

.pm.card .body {
  @apply px-4 py-5 sm:p-6;
}

.pm.card .footer {
  @apply px-4 py-4 sm:px-6 bg-gray-100;
}

@keyframes appear-then-fade {
  0%,
  100% {
    opacity: 0;
  }
  5%,
  60% {
    opacity: 1;
  }
}

.flash {
  &__message {
    animation: appear-then-fade 4s both;
  }
}

nav.subnavigation {
  @apply -mb-px flex space-x-8 container mx-auto;
}

nav.subnavigation a:not(.active) {
  @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm;
}

nav.subnavigation a.active {
  @apply border-primary-500 text-primary-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm;
}

.auth0-lock.auth0-lock .auth0-lock-cred-pane {
  background: rgba(255,255,255,.9);
}

.auth0-lock-header {
  display: none !important;
  visibility: hidden;
}

.auth0-lock-submit {
  @apply !bg-sky-500;
}

.report.webtable .footnote { 
  @apply text-sm mt-6 text-gray-700;
}

.jmi.dropdown {
  @apply relative;
}

.jmi.dropdown .right.menu {
  @apply absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;  
}

.jmi.dropdown .left.menu {
  @apply absolute left-0 z-20 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;  
}

.jmi.dropdown .menu .item {
  @apply text-gray-700 block px-4 py-2 text-sm;  
}

.jmi.secondary.tabs {
  @apply flex flex-row;
}

.jmi.secondary.tabs .tab {
  @apply text-gray-500 hover:text-gray-700 rounded-md px-3 py-2 text-sm font-medium flex flex-row justify-center;
}

.jmi.secondary.tabs .paratek{
  @apply text-white hover:text-white rounded-md px-3 py-2 text-sm font-medium flex flex-row justify-center;
}

.jmi.secondary.tabs .active.paratek{
  @apply text-primary-800 bg-primary-100;
}

.jmi.secondary.tabs .active.tab {
  @apply text-primary-800 bg-primary-100;
}

.auth0-sso-notice-container {
  @apply flex flex-row justify-center items-center space-x-6;
}


.susceptible-dilution {
  background-color: #C7E5B5;
}

.intermediate-dilution {
  background-color: #ecec84;
}


.resistant-dilution {
  background-color: #ffb3b3;
}


.auth0-lock.auth0-lock .auth0-lock-terms .auth0-lock-sign-up-terms-agreement a {
  @apply text-sky-600 hover:text-sky-800 hover:underline cursor-pointer;
}


.dotted {
  stroke-dasharray: 10,15;
}

.paratek.login-section {

  height: calc(100vh - 100px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img  {
    margin: 0 auto;
    height: 90px;

    margin-top: -50px;
    display: block;
    margin-bottom: 20px;
  }

  .auth0-lock.auth0-lock .auth0-lock-cred-pane {
    background: rgba(255,255,255,.9);
  }

  .auth0-lock-center, .root {
    padding: 0px;
  }

  .auth0-lock-header {
    display: none;
    visibility: hidden;
  }

  .auth0-lock-submit {
    background-color: #e2841e !important;
  }

  p.keystone {
    display: block;
    position: absolute;
    left: 59px;
    top: 94px;
    font-size: 42px;
    color: #a5a5a5;
  }

  sup.keystone {
    font-size: 50%;
    top: -11px;
  }

  p.keystone-large {
    display: block;
    margin-left: 25px;
    margin-top: -30px;
    margin-bottom: 0px;
    font-size: 96px;
    color: #a5a5a5;
  }

  sup.keystone-large {
    font-size: 25%;
    top: -55px;
  }

}

.copy {
  color: rgba(255,255,255,.9);
  max-width: 600px;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 28px;
  word-spacing: 2px;
  text-align: center;
  margin-top: -10px;
}

.ui.button.learn-more{
  margin: 0 auto;
  display: block;
}

@media only screen and (min-width: 1200px) {
  .ui.grid.container {
    width: 98% !important;
  }

  .ui.container {
    width: 95% !important;
  }
}

.info {
  padding-top: 30px !important;
  padding-left: 100px !important;
  padding-right: 100px !important;
  padding-bottom: 80px !important;
  background-color: #f1f1f1;

  .callout {
    font-family: "Helvetica";
    letter-spacing: 2px;
  }

  p {
    font-size: 17px;
    color: #797979;
    line-height: 26px;
  }

  .sample-svg{
    width: 75%;
    margin: 0 auto;
    margin-bottom: 15px;
  }
}

.subset-help {
  color: #b9b9b9;
  text-decoration: underline;
  font-family: Helvetica;
  &:hover {
    color: white;
  }
}

.copy {
  color: rgba(255,255,255,.9);
  max-width: 600px;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 28px;
  word-spacing: 2px;
  text-align: center;
  margin-top: -10px;
}

.divider{
  margin: 0 0.25em;
  color: black;
}

#main-content {
  width: 95% !important;
  padding: 20px;
  border-radius: 25px;
  margin: 5px auto 30px;
  &.media-mobile {
    margin-top: 90px;
  }
}

.paratek-container {
  width: 95%;
}

.content-container{
  min-height: 100vh;
}

.pagehead {
  .back-to-original .button {
    background-color: rgb(255, 255, 255) !important;
  }
  i {
    color: #e3953b;
  }
}

.pagehead.slim {
  padding: 0 0 0 100px;
  img.jmi-icon {
    margin-top: 25px;
  }
}

.header-container {
  float: left;
  display: block;
  width: 100%;
  margin-top: -50px;
  margin-left: 20rem;
}

.nav .item {
  /* float: left; */
  height: 102px;
  display: inline-block;
  width: 75px;
  text-align: center;
  font-size: 11px;
  color: rgba(255,255,255,.85);
  letter-spacing: 1.4px;
  margin: 5px;
  margin-top: -25px;

  &:hover {
    cursor: pointer;
  }

  &:after{
    content: "";
    width: 70px;
    display: block;
    border-radius: 12px;
    height: 70px;
  }

  &.publication:after {
    background: url("pub_icon.png") no-repeat center 8px/50px 55px, 
    linear-gradient(#e2841e, #deaa71) no-repeat bottom center;
  }

  &.publication:hover:after, &.publication.active:after{
    background: url("pub_icon.png") no-repeat center 8px/50px 55px,
    linear-gradient(#9dae40, #9dae40) no-repeat bottom center;
  }

  &.kpi:after {
    background: url("kpi_icon.png") no-repeat center 10px/50px 50px, linear-gradient(#e2841e, #deaa71) no-repeat bottom center;
  }

  &.kpi:hover:after, &.kpi.active:after{
    background: url("kpi_icon.png") no-repeat center 10px/50px 50px,
    linear-gradient(#9dae40, #9dae40) no-repeat bottom center;
  }

  &.heatmap:after {
    background: url("heat_map_icon.png") no-repeat -20px 5px/145px 65px,
    linear-gradient(#e2841e, #deaa71) no-repeat bottom center;
  }

  &.heatmap:hover:after, &.heatmap.active:after{
    background: url("heat_map_icon.png") no-repeat -20px 5px/145px 65px,
    linear-gradient(#9dae40, #9dae40) no-repeat bottom center;
  }

  &.adhoc:after {
    background: url("adhoc_icon.png") no-repeat 5px 3px/65px 80px,
    linear-gradient(#e2841e, #deaa71) no-repeat bottom center;
  }

  &.adhoc:hover:after, &.adhoc.active:after{
    background: url("adhoc_icon.png") no-repeat 5px 3px/65px 80px,
    linear-gradient(#9dae40, #9dae40) no-repeat bottom center;
  }
}

.ui.fixed {
  position: inherit;
}

p.keystone {
  display: block;
  position: absolute;
  left: 9rem;
  top: 100px;
  font-size: 42px;
  color: #a5a5a5;
}

sup.keystone {
  font-size: 50%;
  top: -11px;
}

p.keystone-large {
  display: block;
  margin-left: 25px;
  margin-top: -30px;
  margin-bottom: 0px;
  font-size: 96px;
  color: #a5a5a5;
}

sup.keystone-large {
  font-size: 25%;
  top: -55px;
}

.icon-color{
  filter: invert(53%) sepia(20%) saturate(207%) hue-rotate(165deg) brightness(95%) contrast(86%);
  height: 30px;
  width: 26px;
  opacity: 0.6;
  margin-right: 1rem;
}

.icon-color-small{
  filter: invert(53%) sepia(20%) saturate(207%) hue-rotate(165deg) brightness(95%) contrast(86%);
  height: 30px;
  width: 26px;
  opacity: 0.6;
  margin-right: 1rem;
}

.ui.horizontal.divider{
  line-height: normal;
  color: rgb(126, 151, 104);
  font-size: 30px;
  display: block;
  text-align: center;
  width: 100%;
}

.ember-view {
  max-width: 750px;
  margin: 0 auto;
  color: rgba(255,255,255,.9);
}

h1.ui.header {
  color: #dbe668;
  font-size: 2rem;
}

h3.ui.header {
  color: #a2a774;
  font-size: 1.28571429rem;
}

.ui.table {
  color: rgba(255,255,255,.8);
  thead th {
    color: #a2a774;
  }
}

.ui.list > .item {
  .description {
    color: rgba(255,255,255,.8);
  }
  .header {
    color: rgba(255,255,255,.8);
  }
}


i.chevron.right.icon, i.caret.right.icon {
  float: none;
  color: rgba(255,255,255,.6) !important;
}

.marked-category {
  font-weight: bold;
  text-decoration: underline;
}

table.ui.table {
  margin-top: 2px;
  margin-bottom: 10px;
  width: 100%;

  td {
    padding: 0.2em 0.78571429em;
    width: 50%;
  }

  th {
    padding-bottom: .3em;
    padding-top: .3em;
  }
}

.content {
  width: 100%;
}

.ui.divider {
  margin: 1rem 0;
  line-height: 1;
  height: 0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: rgba(0,0,0,.85);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 1rem
}

.ui.divider:not(.vertical):not(.horizontal) {
  border-top: 1px solid rgba(34,36,38,.15);
  border-bottom: 1px solid rgba(255,255,255,.1)
}

.surveillance-dashboard {
  .nav .item {
    height: 102px;
    width: 90px;
    text-align: center;
    font-size: 12px;
    color: rgba(255,255,255,.85);
    letter-spacing: 2.4px;
    margin: 10px;

    &:hover {
      cursor: pointer;
    }
  
    &:after{
      content: "";
      width: 90px;
      display: block;
      border-radius: 20px;
      height: 90px;
    }

    &.publication:after {
      background: url("pub_icon.png") no-repeat center 10px,
      linear-gradient(#e2841e, #deaa71) no-repeat bottom center;
    }

    &.publication:hover:after{
      background: url("pub_icon.png") no-repeat center 10px,
      linear-gradient(#9dae40, #9dae40) no-repeat bottom center;
    }

    &.kpi:after {
      background: url("kpi_icon.png") no-repeat center 20px,
      linear-gradient(#e2841e, #deaa71) no-repeat bottom center;
    }

    &.kpi:hover:after{
      background: url("kpi_icon.png") no-repeat center 20px,
      linear-gradient(#9dae40, #9dae40) no-repeat bottom center;
    }

    &.heatmap:after {
      background: url("heat_map_icon.png") no-repeat -26px 5px,
      linear-gradient(#e2841e, #deaa71) no-repeat bottom center;
    }

    &.heatmap:hover:after{
      background: url("heat_map_icon.png") no-repeat -26px 5px,
      linear-gradient(#9dae40, #9dae40) no-repeat bottom center;
    }

    &.adhoc:after {
      background: url("adhoc_icon.png") no-repeat 5px 3px,
      linear-gradient(#e2841e, #deaa71) no-repeat bottom center;
    }

    &.adhoc:hover:after{
      background: url("adhoc_icon.png") no-repeat 5px 3px,
      linear-gradient(#9dae40, #9dae40) no-repeat bottom center;
    }

  }

  p.copy {
    max-width: 900px !important;
    text-align: justify !important;
    font-size: 16px !important;
    line-height: 24px !important;

    &.callout {
      font-size: 19px !important;
      line-height: 28px !important;
      font-weight: 500;
      text-align: center !important;
      letter-spacing: 1px;
      color: #d8dfea;
      margin-bottom: 40px;
    }
  }

  .nav {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

}